import {Context} from "@profiscience/knockout-contrib-router";
import {CampaignDto, CategoryDto, Idea, IdeaDto, UserDto} from "../../api/generated";
import {categoryApi, ideaApi} from "../../api/api-wrapper";
import * as moment from 'moment'
import * as ko from "knockout";
import {autobind} from "knockout-decorators";
import _uniqBy from 'lodash-es/uniqBy';
import {ideaAverageRating, ideaStateOptions, isFilterActive, uniqueCampaignList} from "./ideaUtils";
import globalState from "../../global-state";
import {categoryNameTranslated} from "../admin/categoryUtils";
import {App} from "../../app";
import campaign from "../campaigns/campaign";
import {campaignOptions} from "../campaigns/campaignUtils";
import {config} from "../../utils/clientConfigWrapper";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
    categories: CategoryDto[];
}

enum SortOptions {
    StateChanged = <any> 'stateChanged',
    Rating = <any> 'rating',
    Created = <any> 'created'
}

class ViewModel {

    /**
     * The ideas.
     */
    public ideas: Array<IdeaDto>;

    /**
     * Unique list of users (for the search autocomplete).
     */
    public users: Array<UserDto>;

    /**
     * The categories.
     */
    public categories: Array<CategoryDto>;

    /**
     * Ideas filtered and sorted.
     */
    public ideasFiltered: KnockoutComputed<IdeaDto[]>;

    /**
     * Ideas filtered, sorted and limited to a page size.
     */
    public ideasPaged: KnockoutComputed<IdeaDto[]>;

    /**
     * Ideas filtered subscription.
     * Resets the current pagination page whenever the length of the search result changes.
     */
    public ideasFilteredSubscription: KnockoutSubscription;

    /**
     * The current page number for the pagination.
     */
    public currentPage: KnockoutObservable<number>;

    /**
     * The number of items per page.
     */
    public pageSize: KnockoutObservable<number>;

    /**
     * Search term for filtering items.
     */
    public searchTerm: KnockoutObservable<string>;

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * The selected idea state.
     */
    public ideaStateFilter: KnockoutObservable<string>;

    /**
     * The selected category.
     */
    public categoryFilter: KnockoutObservable<string>;

    /**
     * The selected user.
     */
    public userFilter: KnockoutObservable<UserDto>;

    /**
     * The user filter autocomplete query.
     */
    public userQuery: KnockoutObservable<string>;

    /**
     * The date from filter.
     */
    public dateFromFilter: KnockoutObservable<Date>;

    /**
     * The date to filter.
     */
    public dateToFilter: KnockoutObservable<Date>;

    /**
     * The unpublished filter.
     */
    public unPublishedFilter: KnockoutObservable<boolean>;

    /**
     * The campaign filter
     */
    public campaignFilter: KnockoutObservable<string>;

    /**
     * The options for the campaign filter.
     * - Unique list of campaigns
     * - extracted from the ideas list
     * - to exclude campaigns which have no ideas assigned
     */
    public campaignOptions: { text: string; value: number }[];

    /**
     * The sort property
     */
    public sortProperty: KnockoutObservable<string>;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        console.log(ctx.ideas);
        this.ideas = ctx.ideas;
        this.users = _uniqBy(this.ideas.map(idea => idea.user), 'id')
            .filter(user => user.username != 'anonymous');
        this.categories = ctx.categories;
        this.ideasFiltered = this.ideasFilteredComputed();
        this.ideasPaged = this.ideasPagedComputed();
        this.pageSize = ko.observable(8);
        this.currentPage = globalState.ideasSearchFilters.currentPage;
        this.searchTerm = globalState.ideasSearchFilters.searchTerm;
        this.ideaTypeFilter = globalState.ideasSearchFilters.ideaTypeFilter;
        this.ideaStateFilter = globalState.ideasSearchFilters.ideaStateFilter;
        this.categoryFilter = globalState.ideasSearchFilters.categoryFilter;
        this.userFilter = globalState.ideasSearchFilters.userFilter;
        this.userQuery = globalState.ideasSearchFilters.userQuery;
        this.dateFromFilter = globalState.ideasSearchFilters.dateFromFilter;
        this.dateToFilter = globalState.ideasSearchFilters.dateToFilter;
        this.unPublishedFilter = globalState.ideasSearchFilters.unPublishedFilter
        this.campaignFilter = globalState.ideasSearchFilters.campaignFilter;
        this.sortProperty = globalState.ideasSearchFilters.sortProperty;
        this.campaignOptions = campaignOptions(uniqueCampaignList(ctx.ideas));
        this.ideasFilteredSubscription = this.ideasFiltered.subscribe(() => {
            this.currentPage(1)
        });
    }

    /**
     * Options for sorting.
     */
    public sortOptions() {
        const options = App.enumOptions(SortOptions, 'ideas.sort.');
        if(!config.userRatingsEnabled) {
            return options.filter(option => option.value != SortOptions.Rating.toString());
        }
        return options;
    }

    /**
     * Get the options for the idea type filter.
     * State submission is not visible.
     * State submitted is visible for the admin only
     */
    public ideaStateOptions() {
        return ideaStateOptions()
            .filter(option => option.value != 'submission')
            .filter(option => globalState.user().admin ? true : option.value != 'submitted');
    }

    /**
     * Format an user for the autocomplete.
     * @param user
     */
    public formatUser(user: UserDto) {
        return App.username(user);
    }

    /**
     * Get the category name or the translation if it exists.
     * @param category
     */
    public categoryName(category: CategoryDto) {
        return categoryNameTranslated(category);
    }

    /**
     * Select an user for the autocomplete.
     * Returns the user name to be displayed in the search field.
     * @param user
     */
    @autobind
    public selectUser(user: UserDto): string {
        if (user) {
            this.userFilter(user);
            this.userQuery(this.formatUser(user));
            return this.formatUser(user);
        } else {
            // workaround for copy & paste
            return "" + user;
        }
    }

    /**
     * Reset the user filter.
     */
    @autobind
    public resetUserFilter() {
        this.userFilter(null);
        this.userQuery("");
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    private ideasFilteredComputed(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (isFilterActive(this.categoryFilter) && idea.category
                    && idea.category.id.toString() != this.categoryFilter()) {
                    return false;
                }
                if (isFilterActive(this.campaignFilter) &&
                    (!idea.campaign || idea.campaign.id.toString() != this.campaignFilter())) {
                    return false;
                }
                if (isFilterActive(this.ideaStateFilter) && idea.state && idea.state.toString() !== this.ideaStateFilter()) {
                    return false;
                }
                if (isFilterActive(this.ideaTypeFilter) && idea.type && idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                if (this.userFilter() && idea.user.id !== this.userFilter().id) {
                    return false;
                }
                if (this.unPublishedFilter() === true && idea.published === true) {
                    return false;
                }
                if (this.dateFromFilter() !== undefined && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if (isFilterActive(this.searchTerm) && !this.searchTermMatches(idea, this.searchTerm())) {
                    return false;
                }
                return true;
            }).sort((idea1, idea2) => {
                if (this.sortProperty() == SortOptions.Rating.toString()) {
                    // sort by rating and then by number of ratings
                    const averageCompare = ideaAverageRating(idea2) - ideaAverageRating(idea1);
                    const counterCompare = idea2.ratingsCnt - idea1.ratingsCnt;
                    return averageCompare == 0 ? counterCompare : averageCompare;

                } else if (this.sortProperty() == SortOptions.Created.toString()) {
                    return (<string><any>idea2.created).localeCompare(<string><any>idea1.created);
                }
                return (<string><any>idea2.statechanged).localeCompare(<string><any>idea1.statechanged);
            })
        });
    }

    /**
     *  Ideas filtered and sorted and limited to a page size.
     */
    @autobind
    private ideasPagedComputed(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            const pageStart = (this.currentPage() - 1) * this.pageSize();
            const pageEnd = pageStart + this.pageSize();
            console.debug("total ideas: ", this.ideasFiltered().length, " pagination start: ", pageStart, " pagination end: ", pageEnd);
            return this.ideasFiltered().slice(pageStart, pageEnd);
        });
    }

    /**
     * Search for the term within the idea title.
     * Search is performed lowercase. Whitespace is used to split the term. All terms must match.
     *
     * @param idea
     * @param searchTerm
     */
    private searchTermMatches(idea: IdeaDto, searchTerm: string) {
        const title = idea.title ? idea.title.trim().toLowerCase() : "";
        const terms: string[] = searchTerm.trim().toLowerCase().split(" ");

        // the item name must match all search terms
        return terms.every(term => {
            if (title.indexOf(term) > -1) {
                return true;
            }
            return false;
        });
    }

    /**
     * Dispose subscriptions.
     */
    @autobind
    public dispose() {
        this.ideasFilteredSubscription.dispose();
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideas.html'),
    componentName: "ideas",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Ideenpool`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            }),
            categoryApi.getCategories().then(categories => {
                ctx.categories = categories;
            })
        ]);
    }
};
