import * as modal from "../../components/elements/modal/modal";
import * as ko from "knockout";
import {UserDto} from "../../api/generated";
import {UserSearchForm} from "../../forms/user-search-form";
import {autobind} from "knockout-decorators";
import {postbox} from "../../components/util/postbox";


class ShareIdeaViewModel {

    /**
     * Select a user to create a new evaluation.
     */
    public userForm: UserSearchForm;

    /**
     * List of recipients.
     */
    public recipients: KnockoutObservableArray<UserDto>;

    /**
     * Subscription if a user is selected.
     * Add him to the recipients list.
     */
    public userSelectedSubscription: KnockoutSubscription;

    /**
     * The message.
     */
    public message: KnockoutObservable<string>;

    constructor(ctx: modal.ModalBodyComponentContext) {
        if (!ctx.params.recipients || !ko.isObservable(ctx.params.recipients)) {
            throw new Error("Recipient lis must be an obserable.");
        }
        if (!ctx.params.message || !ko.isObservable(ctx.params.message)) {
            throw new Error("Recipient lis must be an obserable.");
        }
        this.recipients = ctx.params.recipients;
        this.message = ctx.params.message;
        this.userForm = new UserSearchForm();
        this.userForm.userHasSubscription(true);
        this.userSelectedSubscription = this.userForm.user.subscribe(user => {
            if (user) {
                this.addRecipient();
            }
        })
    }

    @autobind
    public addRecipient() {
        if (this.userForm.user().email) {
            this.recipients.push(this.userForm.user());
        } else {
            postbox.addError("idea.details.share.error.userEmailAddressMissing")
        }
        this.userForm.resetUser();
    }

    @autobind
    public removeRecipient(user: UserDto) {
        this.recipients.splice(this.recipients.indexOf(user), 1);
    }

    @autobind
    public dispose() {
        this.userSelectedSubscription.dispose();
    }
}

const componentShareIdeaModalBody: KnockoutComponentTypes.Config = {
    viewModel: (params: modal.ModalBodyComponentContext) =>
        new ShareIdeaViewModel(params),
    template: <string>require('./share.html')
};

export default componentShareIdeaModalBody;

if (!ko.components.isRegistered('share-idea-modal-body')) {
    ko.components.register("share-idea-modal-body", componentShareIdeaModalBody);
}