import {IdeaDto, UserDto} from "../../api/generated";
import * as ko from "knockout";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import {ideaApi, userApi} from "../../api/api-wrapper";
import globalState from "../../global-state";


class ViewModel {

    /**
     * The search term.
     */
    public searchTerm: KnockoutObservable<string>;

    /**
     * The min length for the search term.
     */
    public searchTermMinLength: number = 3;

    /**
     * The users search result.
     */
    public users: KnockoutObservable<UserDto[]>;

    /**
     * The users search result filtered.
     */
    public usersFiltered: KnockoutComputed<UserDto[]>;

    /**
     * Users limited to a page size.
     */
    public usersPaged: KnockoutComputed<UserDto[]>;

    /**
     * The current page number for the pagination.
     */
    public currentUsersPage: KnockoutObservable<number>;

    /**
     * The ideas search result.
     */
    public ideas: KnockoutObservable<IdeaDto[]>;

    /**
     * The ideas search result filtered.
     */
    public ideasFiltered: KnockoutComputed<IdeaDto[]>;

    /**
     * Ideas limited to a page size.
     */
    public ideasPaged: KnockoutComputed<IdeaDto[]>;

    /**
     * The current page number for the pagination.
     */
    public currentIdeasPage: KnockoutObservable<number>;

    /**
     * The number of items per page.
     */
    public pageSize: KnockoutObservable<number>;

    /**
     * The total number of hits.
     */
    public hits: KnockoutComputed<number>;

    /**
     * Search result change subscription.
     * Resets the current pagination page whenever the length of the search result changes.
     */
    public hitsSubscription: KnockoutSubscription;

    /**
     * Constructor.
     */
    constructor() {
        this.searchTerm = globalState.searchFilters.searchTerm;
        this.pageSize = ko.observable(8);

        // Users
        this.users = asyncComputed(async () => {
            return (this.searchTerm().length >= this.searchTermMinLength) ?
                userApi.getUsers(this.searchTerm()) : Promise.resolve([]);
        }, []);
        this.usersFiltered = ko.pureComputed(() => this.users());
        this.currentUsersPage = ko.observable(1);
        this.usersPaged = ko.pureComputed(() => {
            const pageStart = (this.currentUsersPage() - 1) * this.pageSize();
            const pageEnd = pageStart + this.pageSize();
            return this.usersFiltered().slice(pageStart, pageEnd);
        });

        // Ideas
        this.ideas = asyncComputed(async () => {
            return (this.searchTerm().length >= this.searchTermMinLength) ?
                ideaApi.getIdeas(this.searchTerm()) : Promise.resolve([]);
        }, []);
        this.currentIdeasPage = ko.observable(1);
        this.ideasFiltered = ko.pureComputed(() => this.ideas());
        this.ideasPaged = ko.pureComputed(() => {
            const pageStart = (this.currentIdeasPage() - 1) * this.pageSize();
            const pageEnd = pageStart + this.pageSize();
            return this.ideasFiltered().slice(pageStart, pageEnd);
        });

        this.hits = ko.pureComputed(() =>
            this.ideasFiltered().length + this.usersFiltered().length
        );
        this.hitsSubscription = this.hits.subscribe(newValue => {
            this.currentIdeasPage(1);
            this.currentUsersPage(1);
        });

    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./search.html'),
    componentName: "search",
    loader: () => {
        document.title = `${document.title} - Suche`;
        return Promise.resolve();
    }
};
