import {contentApi} from "../../api/api-wrapper";
import {WidgetDto} from "../../api/generated";
import {autobind, computed} from "knockout-decorators";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import * as ko from "knockout";
import '../../components/elements/news/news-list-item';
import '../../components/elements/news/pinned-news-item';
import {WidgetContentNews} from "../editor/_common";
import globalState from "../../global-state";

interface NewsListViewModelContext extends Context {
    newsWidgets: WidgetDto[];
}

class NewsListViewModel {

    public newsWidgets: WidgetDto[];

    /**
     * The current page number for the pagination.
     */
    public currentPage: KnockoutObservable<number>;

    /**
     * The number of items per page.
     */
    public pageSize: KnockoutObservable<number>;


    constructor(ctx: NewsListViewModelContext) {
        // show invisible news to admin
        this.newsWidgets = ctx.newsWidgets
            .filter(widget => globalState.user().admin || (<WidgetContentNews>widget.content).visible);
        this.currentPage = ko.observable(1);
        this.pageSize = ko.observable(globalState.appVars.newsWidgetsPageSize || 9);
    }

    public get pinnedNewsWidget(): WidgetDto {
        const visibleWidgets = this.newsWidgets.filter(widget =>(<WidgetContentNews>widget.content).visible);
        const pinnedWidgets = visibleWidgets.filter(widget => (<WidgetContentNews>widget.content).pinned);
        if (pinnedWidgets.length > 0) {

            return pinnedWidgets[0];
        } else if (visibleWidgets.length > 0) {

            return visibleWidgets[0];
        } else {

            return null;
        }
    }

    @computed
    public get newsWidgetsPaged(): WidgetDto[] {
        const pageStart = (this.currentPage() - 1) * this.pageSize();
        const pageEnd = pageStart + this.pageSize();
        return this.newsWidgets.slice(pageStart, pageEnd);
    }

    @autobind
    public addNews() {
        return Router.update('/news/neu');
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: NewsListViewModel,
    template: require('./newsList.html'),
    componentName: "news-list",
    loader: (ctx: NewsListViewModelContext) => {
        document.title = `${document.title} - News`;
        return contentApi.getWidgets('NEWS')
            .then(newsWidgets => ctx.newsWidgets = newsWidgets);
    }
};
