import * as ko from "knockout";
import {CampaignDto, Idea, IdeaDto, UserDto} from "../../api/generated";
import i18nextko from "../../bindings/i18nko";
import {App} from "../../app";
import {config} from "../../utils/clientConfigWrapper";
import StateEnum = Idea.StateEnum;
import TypeEnum = Idea.TypeEnum;
import _uniqBy from "lodash-es/uniqBy";


/**
 * Regex to test valid idea attachments.
 */
export const ideaAttachmentsRegex = /^image\/.+$|^video\/.+$|^application\/pdf$|^application\/vnd\.ms-excel$|^application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document$/;

/**
 * Get the user name or the text for anonymous users.
 *
 * @param user
 * @param anonymous
 */
export function ideaUserName(user: UserDto, anonymous: boolean): KnockoutComputed<string> {
    return ko.pureComputed(() =>
        anonymous || !user ? i18nextko.t('idea.anonymousUser')() : App.username(user)
    );
}

/**
 * Get the main image as a background style or the category image as a fallback.
 *
 * @param idea
 */
export function ideaMainImageStyle(idea: IdeaDto): string {
    const categoryImageName = idea.category ? idea.category.defaultImage : null;
    if (idea.attachmentList === undefined || idea.attachmentList.length < 1) {
        return categoryImageName ? `background-image: url('${config.attachmentEndpoint}${categoryImageName}'), url('/${config.publicPath}/images/dummy.png');` : "";
    }
    let attachmentDto = idea.attachmentList.find(attachment => attachment.mainImage === true);
    if (attachmentDto === undefined) {
        attachmentDto = idea.attachmentList.find(attachment => attachment.image === true);
    }
    const imageName = attachmentDto ? attachmentDto.filename : categoryImageName;
    return imageName ? `background-image: url('${config.attachmentEndpoint}${imageName}'), url('/${config.publicPath}/images/dummy.png');` : "";
}

/**
 * Get the options for the idea state filter.
 */
export function ideaStateOptions() {
    return App.enumOptions(StateEnum, "ideas.state.");
}

/**
 * Check whether a filter is active.
 */
export function isFilterActive(filter: KnockoutObservable<string>): boolean {
    return filter() !== undefined && filter().toString().trim().length > 0;
}

/**
 * Check whether an idea is active.
 * @param idea
 */
export function isIdeaActive(idea: IdeaDto): boolean {
    return idea.state && !(idea.state == IdeaDto.StateEnum.Implemented || idea.state == IdeaDto.StateEnum.Canceled ||
        idea.state == IdeaDto.StateEnum.NotNew);
}

/**
 * Get the average rating of an idea.
 * @param idea
 */
export function ideaAverageRating(idea: IdeaDto): number {
    if (idea.ratingsCnt > 0) {
        return (Math.round((idea.ratingsSum / idea.ratingsCnt) * 10) / 10);

    } else {
        return 0;
    }
}

/**
 * Get the average rating of an idea formatted as a string.
 * @param idea
 */
export function ideaAverageRatingFormatted(idea: IdeaDto):string {
    return ideaAverageRating(idea).toLocaleString(config.locale);
}

/**
 * Get a unique list of campaigns which have ideas assigned.
 * @param ideas
 */
export function  uniqueCampaignList(ideas: IdeaDto[]): CampaignDto[] {
    return _uniqBy(
        ideas.reduce((campaigns: CampaignDto[], idea: IdeaDto) => {
            if (idea.campaign && idea.campaign.id) {
                campaigns.push(idea.campaign);
            }
            return campaigns;
        }, []),
        'id');
}
