import * as ko from "knockout";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {
    AttachmentDto,
    CategoryDto,
    CertificateInfo,
    CommentDto,
    Evaluation,
    EvaluationDto,
    IdeaDto,
    IdeaMember,
    IdeaMemberDto,
    ShareIdeaEmailDto,
    UserDto
} from "../../api/generated";
import {ideaApi} from "../../api/api-wrapper";
import {CommentForm} from "../../components/comment/comment";
import {autobind, computed, observable, observableArray} from "knockout-decorators";
import {postbox} from "../../components/util/postbox";
import globalState from "../../global-state";
import {ideaAttachmentsRegex, ideaAverageRatingFormatted, ideaMainImageStyle, ideaUserName} from "../ideas/ideaUtils";
import {evaluationDeadline} from "../evaluation/evaluationUtils";
import "../../components/elements/attachments/images-carousel"
import {categoryNameTranslated} from "../admin/categoryUtils";
import {createConfirmModal, createModal} from "../../components/elements/modal/modal";
import i18nextko from "../../bindings/i18nko";
import "./share";
import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import {config} from "../../utils/clientConfigWrapper";
import {ViewModel as RatingViewModel} from "../../components/rating/rating";
import moment = require("moment");
import ImplementEnum = Evaluation.ImplementEnum;
import RoleEnum = IdeaMember.RoleEnum;


class ViewModelContext extends Context {
    idea: IdeaDto;
    members: IdeaMemberDto[];
    relatedIdeas: IdeaDto[];
    comments: CommentDto[];
    evaluations: EvaluationDto[];
    bookmarked: boolean;
    liked: boolean;
    rated: number;
    fromCreateHint: string;
}

class IdeaEditForm {
    public highlighted: KnockoutObservable<boolean>;

    constructor(idea: IdeaDto) {
        // noinspection RedundantConditionalExpressionJS
        this.highlighted = ko.observable(idea.highlighted === true ? true : false);
    }

    @autobind
    public toggleHighlighted() {
        this.highlighted(!this.highlighted());
    }
}

class CertificatetForm {

    public text: KnockoutObservable<string>;

    constructor() {
        this.text = ko.observable("").extend({required: true});
    }

    @autobind
    public reset() {
        this.text("");
    }
}

class ViewModel extends FileUploadViewModel {

    /**
     * The idea.
     */
    @observable({deep: true, expose: true})
    public idea: IdeaDto;

    /**
     * Related ideas
     */
    @observable({deep: false, expose: false})
    public relatedIdeas: IdeaDto[];

    /**
     * The idea member list with role submitter.
     */
    public submitters: UserDto[];

    /**
     * The idea member list except role submitter.
     */
    public members: IdeaMemberDto[];

    @observableArray({deep: false, expose: false})
    public users: UserDto[];

    /**
     * Flag whether the idea is bookmarked.
     */
    public bookmarked: KnockoutObservable<boolean>;

    /**
     * The admin form to edit some properties of the idea.
     */
    public form: IdeaEditForm;

    /**
     * Subscription for the highlighted change.
     */
    private ideaHighlightedSubscription: KnockoutSubscription;

    /**
     * Comments for the idea.
     */
    @observable({deep: false, expose: false})
    public comments: CommentDto[];

    /**
     * Evaluations for the idea.
     */
    @observable({deep: true, expose: true})
    public evaluations: EvaluationDto[];

    /**
     * The comment form.
     */
    public commentForm: CommentForm;

    /**
     * Get the active evaluation.
     * If the idea has a feedback set return the first evaluation.
     */
    public activeEvaluation: KnockoutComputed<EvaluationDto>;

    /**
     * Get the active evaluation text.
     * For existing ideas the feedback text from the idea.
     * For new ideas the evaluation description.
     */
    public activeEvaluationText: KnockoutComputed<string>;

    /**
     * Get the implementation description of the active evaluation.
     */
    public implementationDescription: KnockoutComputed<string>;

    /**
     * Get the implementation description of the active evaluation.
     */
    public implementationAttachmentList: KnockoutComputed<AttachmentDto[]>;

    /**
     * The implementation date.
     * It's the implementation date of the active evaluation which is in state Implement.
     */
    public implementationDate: KnockoutComputed<string>;

    /**
     * Is the likes count visible?
     */
    public showLikes: KnockoutComputed<boolean>;

    public showRatings: KnockoutComputed<boolean>;

    public averageRatings: KnockoutComputed<string>;

    public rating: KnockoutObservable<number>;

    public liked: KnockoutObservable<boolean> = ko.observable(true);

    /**
     * The recipients for sharing an idea via email
     */
    public recipients: KnockoutObservableArray<UserDto>;

    /**
     * Message for sharing an idea
     */
    public message: KnockoutObservable<string>;

    /**
     * The text for a certificate.
     */
    public certificateForm: CertificatetForm;

    /**
     * Hint if this page is displayed after saving the idea as a draft or after submitting the idea.
     */
    @observable({deep: false, expose: false})
    public fromCreateHint: string;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.idea = ctx.idea;
        this.submitters = ctx.members
            .filter(ideaMember => ideaMember.accepted == true && ideaMember.role == RoleEnum.Submitter)
            .map(ideaMember => ideaMember.user);
        this.members = ctx.members
            .filter(ideaMember => ideaMember.accepted == true && ideaMember.role != RoleEnum.Submitter);
        this.users = ctx.members.map(member => member.user);
        this.users.push(this.idea.user);

        this.relatedIdeas = ctx.relatedIdeas;
        this.bookmarked = ko.observable(ctx.bookmarked);
        this.form = new IdeaEditForm(this.idea);
        this.comments = ctx.comments;
        this.evaluations = ctx.evaluations;
        this.commentForm = new CommentForm();
        this.certificateForm = new CertificatetForm();
        this.activeEvaluation = ko.pureComputed(() => {
            return this.findActiveEvaluation();
        });
        this.activeEvaluationText = ko.pureComputed(() => {
            return this.findActiveEvaluationText();
        });
        this.implementationDescription = ko.pureComputed(() => {
            if (this.activeEvaluation() && this.activeEvaluation().implementationApproved &&
                this.activeEvaluation().implementationDescription &&
                this.activeEvaluation().implementationDescription.trim().length > 0) {
                return this.activeEvaluation().implementationDescription;
            }
            return null;
        });
        this.implementationAttachmentList = ko.pureComputed(() =>
            this.activeEvaluation() ? this.activeEvaluation().implementationAttachmentList : []
        );
        this.implementationDate = ko.pureComputed(() => {
            const activeEvaluation = this.activeEvaluation();
            if (activeEvaluation && activeEvaluation.implementationDate != null && activeEvaluation.implement == ImplementEnum.Implement) {
                return moment(activeEvaluation.implementationDate, 'YYYY-MM-DD')
                    .format(<string>i18nextko.t('global.format.dateFormat')());
            }
            return "-";
        });
        this.ideaHighlightedSubscription = this.form.highlighted.subscribe((highlighted: boolean) => {
            if (highlighted !== this.idea.highlighted) {
                console.debug("save idea highlighted", this.idea.highlighted, highlighted);
                this.idea.highlighted = highlighted;
                this.saveIdea();
            }
        });

        this.rating = ko.observable(ctx.rated || 0);
        this.liked = ko.observable(ctx.liked || false);
        this.showLikes = ko.pureComputed(() => this.idea.likesCnt > 0);
        this.showRatings = ko.pureComputed(() => this.idea.ratingsCnt > 0);
        this.averageRatings = ko.pureComputed(() => {
            return ideaAverageRatingFormatted(this.idea);
        });

        this.recipients = ko.observableArray([]);
        this.message = ko.observable("");

        this.fromCreateHint = ctx.fromCreateHint !== undefined ? ctx.fromCreateHint : null;

        console.debug("Idea: ", ctx.idea);
        console.debug("bookmarked: ", ctx.bookmarked);
        console.debug("Comments: ", ctx.comments);
        console.debug("Evaluations: ", ctx.evaluations);
    }

    /**
     * Get the styles for the idea main image.
     */
    @autobind
    public ideaMainImageStyle(): string {
        return ideaMainImageStyle(this.idea);
    }

    /**
     * Share idea
     */
    @autobind
    public shareIdea() {
        return createModal(
            {
                headerLabel: i18nextko.i18n.t("idea.details.share.title"),
                closeLabel: i18nextko.i18n.t("global.cancel"),
                confirmLabel: i18nextko.i18n.t("idea.details.share.send"),
                modalBodyComponent: "share-idea-modal-body",
                params: {
                    recipients: this.recipients,
                    message: this.message
                },
                options: { backdrop: 'static' }
            }
        ).then(() => {
            if (this.recipients().length < 1) {
                postbox.addError('idea.details.share.error.recipientsEmpty');
                return Promise.resolve();
            }

            const recipients = this.recipients().map(user => user.email);
            const shareIdeaEmailDto: ShareIdeaEmailDto = {
                recipients: recipients,
                message: this.message()
            };
            return ideaApi.shareIdea(this.idea.id, shareIdeaEmailDto)
                .then(() => {
                    postbox.addSuccess('idea.details.share.success.send');
                    this.recipients([]);
                    this.message("");
                    return Promise.resolve();
                })
                .catch(() => postbox.addError('idea.details.share.error.sendingFailed'));
        }).catch(
            () => Promise.resolve()
        );
    }

    @autobind
    public isMyIdea() {
        return globalState.user().id == this.idea.user.id;
    }

    @autobind
    public isMyEvaluation() {
        return this.activeEvaluation() != null && this.activeEvaluation().expertUser.id == globalState.user().id;
    }

    /**
     * Get the category name or the translation if it exists.
     * @param category
     */
    public categoryName(category: CategoryDto) {
        return categoryNameTranslated(category);
    }

    @computed
    public get implementVisible() {
        return this.activeEvaluation() && this.findActiveEvaluation().implement;
    }

    /**
     * Altgutachten anzeigen.
     * Gutachten ist nicht aktiv, aber veröffentlicht.
     *
     * see https://jira.oeamtc.at/browse/IDW-301
     */
    @computed
    public get oldEvaluations(): EvaluationDto[] {
        if (this.evaluations.length > 1) {
            const evaluations = this.evaluations.filter(evaluation =>
                this.activeEvaluation() != null && this.activeEvaluation().id != evaluation.id &&
                evaluation.evaluated && !evaluation.archived)
            return evaluations.length > 0 ? evaluations : null;
        }
        return null;
    }

    /**
     * Find the active evaluation for this idea.
     */
    private findActiveEvaluation() {
        // One of the evaluations is marked as active and evaluated.
        let activeEvaluation = this.evaluations.find(evaluation => evaluation.active && evaluation.evaluated
            && !evaluation.archived);
        if (activeEvaluation) {
            return activeEvaluation;
        }
        // There is only one evaluation which is marked as evaluated (old evaluations might not been marked as active)
        if (this.evaluations.length == 1 && this.evaluations[0].evaluated && !this.evaluations[0].archived) {
            return this.evaluations[0];
        }
        return null;
    }

    /**
     * Find the description text for the active evaluation.
     */
    private findActiveEvaluationText() {
        // For ideas the active evaluation admin description
        if (this.activeEvaluation()) {
            if (this.activeEvaluation().adminDescription && this.activeEvaluation().adminDescription.trim().length > 0) {
                return this.activeEvaluation().adminDescription;
            }
            // For new ideas the active evaluation admin description or the description
            if (this.activeEvaluation().description && this.activeEvaluation().description.trim().length > 0) {
                return this.activeEvaluation().description;
            }
        }
        return null;
    }

    /**
     * The evaluation deadline
     * either the postponeUntil date
     * or the evaluation timestamp plus 6 weeks.
     *
     * @param evaluation
     */
    public evaluationDeadline(evaluation: EvaluationDto): KnockoutComputed<Date> {
        return evaluationDeadline(evaluation);
    }

    /**
     * Get the user name or the text for anonymous users.
     */
    @autobind
    public ideaUserName(user: UserDto): KnockoutComputed<string> {
        return ideaUserName(user, this.idea.anonymous);
    }

    /**
     * Save the idea state change.
     */
    @autobind
    private saveIdea() {
        return ideaApi.putIdea(this.idea.id, this.idea).then(() => {
            postbox.addInfo('idea.details.success.save');
            return Promise.resolve();
        }).catch(() => {
            postbox.addError('idea.details.error.save');
        });
    }

    /**
     * Set a bookmark for the idea.
     */
    @autobind
    public setBookmark() {
        return ideaApi.setIdeaBookmarked(this.idea.id).then(bookmarked => {
            if (bookmarked) {
                postbox.addInfo('idea.details.success.setBookmark');
            } else {
                postbox.addError('idea.details.error.setBookmark');
            }
            this.bookmarked(bookmarked);
            return Promise.resolve();
        }).catch(() => {
            this.bookmarked(false);
            postbox.addError('idea.details.error.setBookmark');
        });
    }

    /**
     * Unset a bookmark for the idea.
     */
    @autobind
    public unsetBookmark() {
        return ideaApi.unsetIdeaBookmarked(this.idea.id).then(bookmarked => {
            if (!bookmarked) {
                postbox.addInfo('idea.details.success.unsetBookmark');
            } else {
                postbox.addError('idea.details.error.unsetBookmark');
            }
            this.bookmarked(bookmarked);
            return Promise.resolve();
        }).catch(() => {
            this.bookmarked(true);
            postbox.addError('idea.details.error.unsetBookmark');
        });
    }

    /**
     * Save a comment.
     */
    @autobind
    public saveComment() {
        const errors = ko.validation.group(this.commentForm);
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        } else {
            globalState.loading(true);
            const comment: CommentDto = {
                title: this.commentForm.title(),
                comment: this.commentForm.comment(),
                timestamp: new Date(),

            };

            return this.filesPromise(ideaAttachmentsRegex)
                .then(uploads =>
                    comment.uploads = uploads)
                .then(() =>
                    ideaApi.postIdeaComment(this.idea.id, comment))
                .then(commentDto => {
                    // ensure replyList is set, otherwise adding replies wil fail before page reload.
                    if (!commentDto.replyList) {
                        commentDto.replyList = new Array<CommentDto>();
                    }
                    this.comments.unshift(commentDto);
                    this.commentForm.title(null);
                    this.commentForm.comment(null);
                    this.commentForm.toggleVisible();
                    this.resetFilesData();
                })
                .catch(reason => {
                    console.error(reason);
                    postbox.addError('comment.error.saveComment');
                })
                .finally(() =>
                    globalState.loading(false));
        }
    }

    /**
     * Save a comment.
     */
    @autobind
    public issueCertificate() {
        const errors = ko.validation.group(this.certificateForm);
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        } else {
            globalState.loading(true);
            const certificateInfo: CertificateInfo = {
                text: this.certificateForm.text(),
                issued: true
            };
            return ideaApi.issueCertificate(this.idea.id, certificateInfo)
                .then(() => {
                    this.certificateForm.reset();
                    postbox.addSuccess('idea.details.success.issueCertificate');
                    return Router.update(`/idee/${this.idea.id}`, {
                        push: false,
                        force: true
                    });
                })
                .catch(reason => {
                    console.error(reason);
                    postbox.addError('idea.details.error.issueCertificate');
                })
                .finally(() => globalState.loading(false));
        }
    }

    /**
     * Get the link to download a certificate.
     *
     * @param user
     */
    public certificateLink(user: UserDto) {
        return `/ideafactory/api/ideafactory-api/ideas/${this.idea.id}/certificates/${user.id}`
    }

    /**
     * Checkif the certificate link is visible.
     */
    public certificateLinkVisible() {
        return config.certificateEnabled && (globalState.user().admin || this.isMyIdea()) && this.idea.certificateIssued
            && this.idea.certificateIssued === true;
    }

    /**
     * Click handler to like a comment.
     */
    @autobind
    public like() {
        globalState.loading(true);
        return ideaApi.putIdeaLikes(this.idea.id)
            .then(value => {
                globalState.loading(false);
                this.idea.likesCnt = value;
            })
            .catch((err) => {
                globalState.loading(false);
                if (err.status === 409) {
                    postbox.addInfo('idea.details.info.likeExists');

                } else {
                    postbox.addError('idea.details.error.like');
                }
            });
    }

    @autobind
    public handleRate(vm: RatingViewModel) {
        globalState.loading(true);
        vm.editable(false);

        ideaApi.putIdeaRatings(this.idea.id, this.rating())
            .then(rating => {
                this.idea.ratingsCnt = rating.count;
                this.idea.ratingsSum = rating.sum;
            })
            .catch(err => {
                if (err.status === 409) {
                    postbox.addInfo('idea.details.info.ratingExists');

                } else {
                    postbox.addError('idea.details.error.rate');
                }
            })
            .finally(() => {
                globalState.loading(false);
            });
    }

    @computed
    public get isMemberOrSubmitter() {
        return this.users.findIndex(user => user.username === globalState.user().username) > -1;
    }

    @autobind
    public memberRegistrationConfirmation() {
        return createConfirmModal(
            i18nextko.t("idea.details.memberRegistration.confirmText"),
            i18nextko.t("idea.details.memberRegistration.confirmTitle"),
            i18nextko.t("idea.details.memberRegistration.confirmButton"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                const ideaMemberDto = <IdeaMemberDto>{
                    user: globalState.user(),
                    accepted: false,
                    role: IdeaMemberDto.RoleEnum.Codeveloper
                };
                this.users.push(globalState.user());

                return ideaApi.addIdeaMember(this.idea.id, ideaMemberDto).then(() => {
                    postbox.addSuccess('idea.details.success.addMember');
                }).catch(() => {
                    postbox.addError('idea.details.success.addMember');
                })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    @autobind
    public showCertificate(member: UserDto) {
        const filename = `certificate-${member.username}`;

        // IE workaround (https://stackoverflow.com/questions/37522011/permission-denied-for-clicking-link-in-internet-explorer-11)
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            return ideaApi.getCertificate(this.idea.id, member.id).then(pdfData => {
                const pdf = new Blob([pdfData], {type: 'application/pdf'});
                window.navigator.msSaveOrOpenBlob(pdf, `${filename}.pdf`);
            }).catch(err => {
                console.error('failed to get certificate', err);
            });

        } else {
            // open tab immediately to prevent popup blockers
            const pdfWindow = window.open('loader.html', 'print');

            return ideaApi.getCertificate(this.idea.id, member.id).then(pdfData => {
                const pdf = new Blob([pdfData], {type: 'application/pdf'});
                if (pdfWindow) {
                    const pdfUrl = URL.createObjectURL(pdf);
                    pdfWindow.location.assign(pdfUrl);
                    pdfWindow.focus();
                }

                return false;

            }).catch(err => {
                console.error('failed to create certificate', err);
                pdfWindow.close();
            });

        }
    }

    /**
     * Dispose subscriptions.
     */
    @autobind
    public dispose() {
        this.ideaHighlightedSubscription.dispose();
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./idea.html'),
    componentName: "idea",
    loader: (ctx: ViewModelContext) => {
        const likesOrRatingsPromise:Promise<void> = config.userRatingsEnabled ?
            ideaApi.getIdeaUserRating(ctx.params.id).then(rated => { ctx.rated = rated }) :
            ideaApi.getIdeaLiked(ctx.params.id).then(liked => {ctx.liked = liked});

        document.title = `${document.title} - Idee ${ctx.params && ctx.params.id || ''}`;

        return Promise.all([
            ideaApi.getIdea(ctx.params.id, true).then((idea) => ctx.idea = idea),
            ideaApi.getIdeaMembers(ctx.params.id).then(members => ctx.members = members),
            ideaApi.getRelatedIdeas(ctx.params.id, true).then(ideas => ctx.relatedIdeas = ideas),
            ideaApi.getIdeaComments(ctx.params.id).then(comments => ctx.comments = comments),
            ideaApi.getIdeaEvaluations(ctx.params.id).then(evaluations => ctx.evaluations = evaluations),
            ideaApi.isIdeaBookmarked(ctx.params.id).then(bookmarked => ctx.bookmarked = bookmarked),
            likesOrRatingsPromise
        ]);
    }
};
