import {Router} from "@profiscience/knockout-contrib-router";
import {CategoryDto, EvaluationDto, IdeaDto, IdeaMemberDto} from "../../api/generated";
import {campaignApi, categoryApi, ideaApi, userApi} from "../../api/api-wrapper";
import {autobind, observable, unwrap} from "knockout-decorators";
import * as ko from "knockout";
import {postbox} from "../../components/util/postbox";
import {ideaAttachmentsRegex, ideaStateOptions} from "../ideas/ideaUtils";
import globalState from "../../global-state";
import {IdeaEditViewModel, IdeaEditViewModelContext} from "./IdeaEditViewModel";
import {categoryNameTranslated} from "../admin/categoryUtils";
import {App} from "../../app";
import i18nextko from "../../bindings/i18nko";
import {createConfirmModal} from "../../components/elements/modal/modal";
import {UserSearchForm} from "../../forms/user-search-form";
import {evaluationStatusClass} from "../evaluation/evaluationUtils";


class ViewModelContext extends IdeaEditViewModelContext {
    idea: IdeaDto;
    evaluations: EvaluationDto[];
    categories: CategoryDto[];
    ideas: IdeaDto[];
    relatedIdeas: IdeaDto[];
}

class ViewModel extends IdeaEditViewModel {

    /**
     * The ideas to search for related ideas.
     */
    @observable({deep: false, expose: false})
    public ideas: IdeaDto[];

    /**
     * Evaluations for the idea.
     */
    @observable({deep: true, expose: true})
    public evaluations: EvaluationDto[];

    /**
     * Select a user to create a new evaluation.
     */
    public evaluatorForm: UserSearchForm;

    /**
     * Flag whether the user form is visible which can toggled by the user.
     */
    public evaluatorFormVisible: KnockoutObservable<boolean>;

    /**
     * The currently assigned related ideas
     */
    @observable({deep: false, expose: false})
    public relatedIdeas: IdeaDto[];

    /**
     * The available categories to choose from.
     */
    @observable({deep: false, expose: true})
    public categories: CategoryDto[];

    /**
     * The selected category id.
     */
    public selectedCategoryId: KnockoutObservable<number>;

    /**
     * Subscription for category selection.
     * Maps the selected id to the category object.
     */
    public selectedCategorySubscription: KnockoutSubscription;

    /**
     * The query to search for the related ideas.
     */
    public relatedIdeaQuery: KnockoutObservable<string>;

    /**
     * The selected related idea.
     */
    public selectedRelatedIdea: KnockoutObservable<IdeaDto>;

    /**
     * Subscription for the state change.
     */
    private ideaStateSubscription: KnockoutSubscription;

    /**
     * Save the initial state to check if the state has changed.
     * If the user changes the state and then back to the initial value it's not a state change.
     */
    private readonly initialState: IdeaDto.StateEnum;

    /**
     * Save the initial state changed date to restore it if the user restores the initial state.
     */
    private readonly initialStateChangedDate: Date;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super(ctx);
        this.ideas = ctx.ideas;
        this.evaluations = ctx.evaluations;
        this.evaluatorForm = new UserSearchForm();
        this.evaluatorFormVisible = ko.observable(this.evaluations.length < 1)
        this.relatedIdeas = ctx.relatedIdeas;
        this.categories = ctx.categories;
        this.selectedCategoryId = ko.observable(this.idea.category ? this.idea.category.id : 1);
        this.selectedCategorySubscription = this.selectedCategoryId.subscribe(id => {
            if (!this.idea.category || this.idea.category.id !== id) {
                this.idea.category = this.categories.find(category => category.id === id);
            }
        });
        this.relatedIdeaQuery = ko.observable("");
        this.selectedRelatedIdea = ko.observable(null);
        this.initialState = this.idea.state;
        this.initialStateChangedDate = this.idea.statechanged;
        this.ideaStateSubscription = unwrap<IdeaDto.StateEnum>(this.idea, "state").subscribe((state: IdeaDto.StateEnum) => {
            if (this.idea.state === this.initialState) {
                console.debug("State has changed to initial value - statechanged is restored to the initial value",
                    this.initialStateChangedDate);
                this.idea.statechanged = this.initialStateChangedDate;
            } else {
                console.debug("State has changed to new value - statechanged is set to now");
                this.idea.statechanged = new Date();
            }
        });

        unwrap(this.idea, "title").extend({required: true, maxlength: 255});
        unwrap(this.idea, "description").extend({required: true, maxlength: 65535});
        unwrap(this.idea, "advantage").extend({required: true, maxlength: 65535});
        unwrap(this.idea, "audience").extend({required: true, maxlength: 65535});

        console.debug("Edit idea: ", this.idea);
    }

    /**
     * Toggle the evaluation user form.
     */
    @autobind
    public toggleEvaluatorForm() {
        this.evaluatorFormVisible(!this.evaluatorFormVisible());
    }

    /**
     * Add an evaluation for the idea.
     */
    @autobind
    public addEvaluation() {
        globalState.loading(true);

        // Get the UserDto for the ActiveDirectoryUser
        return userApi.getUser(this.evaluatorForm.user().username, "username")
            .then(userDto => {
                let evaluation: EvaluationDto = {
                    submitted: false,
                    postponeAccepted: false,
                    expertUser: userDto
                };
                // Post the new evaluation
                return ideaApi.postIdeaEvaluation(this.idea.id, evaluation, globalState.sendMail());
            })
            .then(evaluation => {
                this.evaluations.push(evaluation)
                this.evaluatorForm.resetUser();
                this.evaluatorFormVisible(false);
            })
            .catch(reason => {
                console.error(reason);
                postbox.addError('idea.details.error.addEvaluation');
            })
            .finally(() => globalState.loading(false));
    }

    /**
     * Get the css class for an evaluation status.
     * @param evaluation
     */
    public evaluationStatusClass(evaluation: EvaluationDto): KnockoutComputed<string> {
        return evaluationStatusClass(evaluation);
    }

    /**
     * Adds an idea member with the role Codeveloper.
     */
    public addIdeaMember() {
        super.addMember(IdeaMemberDto.RoleEnum.Codeveloper);
    }

    /**
     * Format an idea for the autocomplete.
     * @param idea
     */
    public formatRelatedIdea(idea: IdeaDto) {
        return idea.title;
    }

    /**
     * Select an idea for the autocomplete.
     * Returns the idea title to be displayed in the search field.
     * @param idea
     */
    @autobind
    public selectRelatedIdea(idea: IdeaDto): string {
        this.selectedRelatedIdea(idea);
        this.relatedIdeaQuery(this.formatRelatedIdea(idea));
        return this.formatRelatedIdea(idea);
    }

    /**
     * Reset the selected related idea.
     */
    @autobind
    public resetSelectedRelatedIdea() {
        this.selectedRelatedIdea(null);
        this.relatedIdeaQuery("");
    }

    /**
     * Get the options for the idea state.
     */
    public ideaStateOptions() {
        return ideaStateOptions()
    }

    /**
     * Get the category name or the translation if it exists.
     * @param category
     */
    public categoryName(category: CategoryDto) {
        return categoryNameTranslated(category);
    }

    /**
     * Add a related idea.
     */
    @autobind
    public addRelatedIdea() {
        return ideaApi.putRelatedIdea(this.idea.id, this.selectedRelatedIdea().id)
            .then(value => {
                this.relatedIdeas.push(this.selectedRelatedIdea());
                this.resetSelectedRelatedIdea();
                postbox.addInfo('idea.edit.success.addRelated');
            }).catch(reason =>
                postbox.addError('idea.edit.error.addRelated')
            );
    }

    /**
     * Remove a related idea.
     */
    @autobind
    public removeRelatedIdea(idea: IdeaDto) {
        return ideaApi.removeRelatedIdea(this.idea.id, idea.id).then(value => {
            this.relatedIdeas.splice(this.relatedIdeas.indexOf(idea), 1);
            postbox.addInfo('idea.edit.success.removeRelated');
        }).catch(reason =>
            postbox.addError('idea.edit.error.removeRelated')
        );
    }

    /**
     * Delete the idea with a confirm dialog.
     */
    @autobind
    public deleteIdeaConfirmation() {
        return createConfirmModal(
            i18nextko.t("idea.edit.delete.confirmText"), i18nextko.t("idea.edit.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return ideaApi.deleteIdea(this.idea.id).then(() => {
                    postbox.addSuccess('idea.edit.success.delete');
                    return Router.update('/idee', {
                        push: true,
                        force: false
                    });
                })
                    .catch(err => {
                        postbox.addError('idea.edit.error.delete');
                        return Promise.reject(err);
                    })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    /**
     * Delete the idea.
     * Sets the deleted flag and saves the idea.
     */
    @autobind
    public deleteIdea() {
        this.idea.deleted = true;
        ideaApi.deleteIdea(this.idea.id).then(() => {
            postbox.addSuccess('idea.edit.success.delete');
            return Router.update('/idee', {
                push: true,
                force: false
            });
        }).catch(reason => {
            postbox.addError('idea.edit.error.delete');
            return Promise.resolve();
        });
    }

    public hasSubmittedTimestamp(evaluation: EvaluationDto) {
        return evaluation.submittedTimestamp != null;
    }

    /**
     * Validate and save the idea.
     */
    @autobind
    public save() {
        console.debug("Saving idea: ", this.idea);
        const errors = ko.validation.group(this.idea);
        if (errors().length > 0) {
            errors.showAllMessages();
            console.debug("Saving idea errors: ", errors);
            postbox.addError('validation.failed');
            return;
        } else {
            globalState.loading(true);
            return this.filesPromise(ideaAttachmentsRegex)
                .then(uploads =>
                    this.idea.uploads = uploads)
                .then(value =>
                    ideaApi.putIdea(this.idea.id, this.idea)
                        .then(() =>
                            this.saveMembers()))
                .then(() => {
                    postbox.addSuccess('idea.edit.success.save');
                    return Router.update(`/idee/${this.idea.id}/bearbeiten`, {
                        push: false,
                        force: true
                    });
                })
                .catch(err => {
                    console.error(err)
                    postbox.addError('idea.edit.error.save');
                })
                .finally(() =>
                    globalState.loading(false));
        }
    }

    /**
     * Dispose subscriptions.
     */
    @autobind
    public dispose() {
        super.dispose();
        this.ideaStateSubscription.dispose();
        this.selectedCategorySubscription.dispose();
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./edit.html'),
    componentName: "ideaEdit",
    loader: (ctx: ViewModelContext) => {
        const id = ctx.params.id;

        document.title = `${document.title} - Idee Bearbeiten ${ctx.params && ctx.params.id || ''}`;

        if (id == null) {
            ctx.idea = null;
            return Promise.resolve();
        }

        return Promise.all([
            ideaApi.getIdea(ctx.params.id).then(idea => ctx.idea = idea),
            ideaApi.getIdeas(null, null, null, "ref")
                .then(ideas => ctx.ideas = ideas),
            ideaApi.getIdeaEvaluations(ctx.params.id).then(evaluations =>
                ctx.evaluations = evaluations
            ),
            categoryApi.getCategories().then(categories => ctx.categories = categories),
            ideaApi.getRelatedIdeas(ctx.params.id).then(ideas => ctx.relatedIdeas = ideas),
            campaignApi.getCampaigns().then(campaigns => ctx.campaigns = campaigns),
            ideaApi.getIdeaMembers(ctx.params.id).then(members => ctx.members = members)
        ]);
    }
};
