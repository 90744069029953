import {WidgetDto} from "../../api/generated";
import {autobind, computed, observable} from "knockout-decorators";
import {deleteWidget, EditorViewModelContext, loadWidget, saveWidget} from "../editor/widget-utils";
import '../../components/elements/news/sidebar-news-item';
import {contentApi} from "../../api/api-wrapper";
import {Router} from "@profiscience/knockout-contrib-router";
import {config} from "../../utils/clientConfigWrapper";

class ViewModelContext extends EditorViewModelContext {
    latestNews: WidgetDto[];
}

class ViewModel {
    public basePath: string = config.attachmentEndpoint;
    public latestNews: WidgetDto[];

    @observable({deep: true, expose: true})
    public widget: WidgetDto;

    constructor(ctx: ViewModelContext) {
        if (ctx.widget) {
            this.widget = ctx.widget;
        } else {
            this.widget = <WidgetDto>{
                id: null,
                type: WidgetDto.TypeEnum.NEWS,
                content: {
                    teaserImageRef: null
                },
                children: []
            };
        }

        // remove currently viewed news widget from latest news list
        this.latestNews = ctx.latestNews.filter(latestNews =>
            !this.widget.id || this.widget.id !== latestNews.id);

        // ensure latest news list consists of max 3 items (4 are loaded)
        if (this.latestNews.length > 3) {
            this.latestNews.pop();
        }
    }

    @computed
    public get widgetContent() {
        return this.widget.content;
    }

    @autobind
    public saveWidget() {
        // update news widget to prevent duplicating news widgets on newly created news widgets
        return saveWidget(this.widget).then(savedWidget => {
            this.widget.id = savedWidget.id;

            return savedWidget;
        });
    }

    @autobind
    public deleteWidget() {
        return deleteWidget(this.widget).then(() => Router.update('news'));
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./news.html'),
    componentName: "news",
    loader: (ctx: ViewModelContext) => {
        const loadWidgetPromise: Promise<WidgetDto> = ctx.params && ctx.params.id ?
            loadWidget(ctx.params.id) : Promise.resolve(null);
        document.title = `${document.title} - News ${ctx.params && ctx.params.id || ''}`;

        return Promise.all([
            loadWidgetPromise.then(widget =>
                ctx.widget = widget
            ),
            contentApi.getWidgets('NEWS', 4).then(newsWidgets =>
                ctx.latestNews = newsWidgets
            )
        ]);
    }
};
