import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import {autobind, observable, unwrap} from "knockout-decorators";
import {CampaignDto, IdeaDto, IdeaMemberDto} from "../../api/generated";
import {Context} from "@profiscience/knockout-contrib-router";
import * as ko from "knockout";
import {UserSearchForm} from "../../forms/user-search-form";
import {ideaApi, ideaMemberApi, userApi} from "../../api/api-wrapper";
import globalState from "../../global-state";
import {postbox} from "../../components/util/postbox";
import moment = require("moment");
import {campaignOptions, isActive} from "../campaigns/campaignUtils";


export class IdeaEditViewModelContext extends Context {
    idea: IdeaDto;
    campaigns: CampaignDto[];
    members: IdeaMemberDto[]
}

class IdeaMemberForm {

    /**
     * The idea member.
     */
    @observable( { deep: true, expose: false} )
    public member: IdeaMemberDto;

    /**
     * Flag whether the member should be deleted on next save.
     */
    @observable
    public deleted: boolean;

    /**
     * Flag whether the member has been recently added and has to be saved.
     */
    @observable
    public added: boolean;

    /**
     * Subscription for role change.
     */
    public roleModified: KnockoutSubscription;

    /**
     * Subscription for accepted change
     */
    public acceptedModified: KnockoutSubscription;

    /**
     * Flag whether the member has been modified.
     */
    @observable
    public modified: boolean;

    constructor(member: IdeaMemberDto, added: boolean) {
        this.member = member;
        this.added = added;
        this.deleted = false;
        this.modified = false;
        this.roleModified = unwrap(this.member, "role").subscribe(newValue => {
            this.modified = true;
        });
        this.acceptedModified = unwrap(this.member, "accepted").subscribe(newValue => {
            this.modified = true;
        });
    }

    public dispose() {
        this.roleModified.dispose();
        this.acceptedModified.dispose();
    }
}

/**
 * Base view model to edit an idea and assign a campaign.
 */
export class IdeaEditViewModel extends FileUploadViewModel {

    /**
     * The idea.
     */
    @observable({deep: true, expose: true})
    public idea: IdeaDto;

    /**
     * The idea member list.
     */
    @observable({deep: false, expose: false})
    public members: IdeaMemberForm[];

    /**
     * The idea member list without deleted members.
     */
    public membersFilterd: KnockoutComputed<IdeaMemberForm[]>;

    /**
     * Active campaigns.
     */
    public campaigns: CampaignDto[];

    /**
     * Selected campaign id.
     */
    public selectedCampaignId: KnockoutObservable<number>;

    /**
     * Set the ideas campaign if the selected campaign idea changes.
     */
    public selectedCampaignSubscription: KnockoutSubscription;

    /**
     * Select a user to add him as a member.
     */
    public userForm: UserSearchForm;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: IdeaEditViewModelContext) {
        super();
        this.idea = Object.assign({}, this.createIdea(), ctx.idea);
        this.members = ctx.members ? ctx.members.map(member => new IdeaMemberForm(member, false)) : [];
        this.membersFilterd = ko.pureComputed(() => this.members.filter((member => member.deleted == false)));
        // Admin Users will see inactive campaigns too
        this.campaigns = ctx.campaigns.filter(campaign => globalState.user().admin || this.isCampaignActive(campaign));
        this.selectedCampaignId = ko.observable(ctx.params.campaignId ? ctx.params.campaignId : this.ideaCampaignIdea());
        this.selectedCampaignSubscription = this.selectedCampaignId.subscribe(selectedId => {
            const selectedCampaign = this.campaigns.find(campaign => campaign.id == selectedId);
            this.idea.campaign = selectedCampaign === undefined ? null : selectedCampaign;
        });
        this.userForm = new UserSearchForm(true);

        console.debug("Idea: ", this.idea, ctx.idea);
    }

    /**
     * Add a member to the idea.
     */
    @autobind
    public addMember(role: IdeaMemberDto.RoleEnum) {
        // Get the UserDto for the ActiveDirectoryUser
        return userApi.getUser(this.userForm.user().username, "username")
            .then(userDto => {
                const ideaMemberDto = <IdeaMemberDto>{
                    user: userDto,
                    accepted: true,
                    role: role
                };
                this.members.push(new IdeaMemberForm(ideaMemberDto, true));
                this.userForm.resetUser();
            })
            .catch(reason => {
                console.debug(reason);
                postbox.addError('idea.create.error.memberDoesNotExist');
            })
            .finally(() =>
                globalState.loading(false));
    }

    /**
     * Remove a member from the idea
     */
    @autobind
    public removeMember(member: IdeaMemberForm) {
        // member was recently added but not saved so far - just remove him
        if(member.added) {
            this.members.splice(this.members.indexOf(member), 1);
        } else {
            // mark member as deleted.
            member.deleted = true;
        }
    }

    /**
     * Saves the idea members.
     * Adds the ones with added flag, deletes the one with deleted flag.
     */
    @autobind
    public saveMembers(): Promise<(IdeaMemberDto|Response)[]> {

        const promises:Promise<IdeaMemberDto|Response>[] = [];

        this.members.forEach(memberForm => {
            if(memberForm.deleted) {
                promises.push(ideaMemberApi.deleteIdeaMember(memberForm.member.id));
            } else if (memberForm.added) {
                memberForm.member.idea = this.idea;
                promises.push(ideaApi.addIdeaMember(this.idea.id, memberForm.member));
            } else if (memberForm.modified) {
                promises.push(ideaMemberApi.putIdeaMember(memberForm.member.id, memberForm.member));
            }
        });

        return Promise.all(promises);
    }

    /**
     * Get the idea's campaign id or null if the campaign is not set.
     */
    @autobind
    public ideaCampaignIdea() {
        return this.idea.campaign ? this.idea.campaign.id : null;
    }

    /**
     * Check whether a campaign is active.
     *
     * @param campaign
     */
    public isCampaignActive(campaign: CampaignDto) {
        return isActive(campaign);
    }

    /**
     * Get the campaigns as an option list.
     */
    public campaignOptions() {
        return campaignOptions(this.campaigns);
    }

    /**
     * Dispose subscriptions.
     */
    public dispose() {
        this.selectedCampaignSubscription.dispose();
    }

    /**
     * Create an empty idea.
     * All properties which will become observables must be included.
     */
    private createIdea(): IdeaDto {
        return {
            id: null,
            state: IdeaDto.StateEnum.Submission,
            type: IdeaDto.TypeEnum.Decision,
            anonymous: false,
            title: null,
            description: null,
            advantage: null,
            audience: null,
            evaluation: null,
            editComment: null,
            modifiedUser: null,
            campaign: null,
            user: null,
            category: {
                id: 1,
                name: "allgemein"
            },
            deleted: false,
            published: false,
            attachmentList: [],
            uploads: [],
            memberRegistration: null
        }
    }
}
