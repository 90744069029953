import {UploadDto} from "../api/generated";
import {FilesData} from "../pages/editor/_common";
import * as ko from "knockout";
import i18nextko from "../bindings/i18nko";

/**
 * Base view model for file uploads of multiple files.
 */
export class FileUploadViewModel {

    /**
     * The assigned files data.
     */
    public filesData: KnockoutObservable<FilesData>;

    constructor() {
        this.filesData = this.createFilesData();
    }

    /**
     * Get a promise with the UploadDto[] or an empty promise if there are no files.
     *
     * @param regex to filter valid the files.
     *
     */
    public filesPromise(regex, filesData?: KnockoutObservable<FilesData>): Promise<UploadDto[]> {
        const data: KnockoutObservable<FilesData> = filesData ? filesData : this.filesData
        if (data() && data().fileArray().length > 0) {
            return Promise.all(
                data().fileArray()
                    .filter(file =>
                        file.type.search(regex) != -1)
                    .map(file =>
                        this.toUploadDto(file)));
        } else {
            return Promise.resolve([]);
        }
    }

    /**
     * Create an observable for the files data.
     */
    public createFilesData():KnockoutObservable<FilesData> {
        return ko.observable({
            file: ko.observable(),
            dataURL: ko.observable(),
            fileArray: <KnockoutObservableArray<File>>ko.observableArray(),
            base64StringArray: <KnockoutObservableArray<string>>ko.observableArray()
        });
    }

    /**
     * Resets the files data.
     */
    public resetFilesData() {
        this.filesData().file(null);
        this.filesData().dataURL("");
        this.filesData().fileArray([]);
        this.filesData().base64StringArray([]);
    }

    /**
     * Convert a file to an UploadDto.
     *
     * @param file
     */
    private toUploadDto(file: File): Promise<UploadDto> {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = <string>reader.result;
                if (result && result.length > 0) {
                    let encoded = (<string>reader.result).replace(/^data:(.*;base64,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);

                } else {
                    reject(Error("invalid upload size"));
                }
            };
            reader.onerror = error => reject(error);
        }).then(base64data => {
            return <UploadDto>{
                filename: file.name,
                mainImage: false,
                mimeType: file.type,
                data: base64data
            }
        });
    }

    /**
     * The options for the file upload field.
     */
    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("global.fileUpload.noFileText"),
            buttonText: i18nextko.t("global.fileUpload.button.choose"),
            changeButtonText: i18nextko.t("global.fileUpload.button.change"),
            clearButtonText: i18nextko.t("global.fileUpload.button.clear")
        };
    }
}
