import {userApi} from "../../api/api-wrapper";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {UserDto} from "../../api/generated";
import {autobind} from "knockout-decorators";
import '../../components/elements/user/edit-user';

class ViewModelContext extends Context {
    user: UserDto;
}

class ViewModel {
    public user: UserDto;

    constructor(ctx: ViewModelContext) {
        this.user = ctx.user;
    }

    @autobind
    public editUserSuccess() {
        return Router.update(`/app/`, {
            push: true,
            force: true
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./complete.html'),
    componentName: "complete",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Profil erstellen ${ctx.params && ctx.params.id || ''}`;
        return userApi.getUser(ctx.params.id).then((user) => ctx.user = user);
    }
};
