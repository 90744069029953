import {WidgetDto} from "../../api/generated";
import {autobind} from "knockout-decorators";
import {EditorViewModelContext, loadWidget, saveWidget} from "../editor/widget-utils";
import {config} from "../../utils/clientConfigWrapper";

class ViewModel {

    public widget: WidgetDto;

    constructor(ctx: EditorViewModelContext) {
        if (ctx.widget) {
            this.widget = ctx.widget;

        } else {
            this.widget = {
                type: WidgetDto.TypeEnum.PAGE,
                content: {
                    layout: null
                },
                children: []
            };
        }
    }

    @autobind
    public saveWidget() {
        return saveWidget(this.widget);
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./infos.html'),
    componentName: "infos",
    loader: (ctx: EditorViewModelContext) => {
        document.title = `${document.title} - Info`;
        return loadWidget(config.widgetIds.info).then(widget => {
            ctx.widget = widget;
            return Promise.resolve();
        });
    }
};
